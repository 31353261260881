<template>
	<v-card>
		<v-card-title  class="justify-center" style="font-size: xx-small;">
			{{ "EVOLUCIÓN DE ENFERMEDADES - PLAGAS - MALEZAS" }}
		</v-card-title>
		
		<v-card-text>
			<template>
				<LineChartGenerator
					:chart-options="chartOptions"
					:chart-data="chartData"
					:chart-id="chartId"
					:dataset-id-key="datasetIdKey"
					:plugins="plugins"
					:css-classes="cssClasses"
					:styles="styles"
					:width="width"
					:height="height"
				/>
			</template>
			
		</v-card-text>
	</v-card>
</template>


<script>
	import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
	import SSelectLot from "@/components/HarvestSchedule/SSelectLot.vue";

	import _sVisit from "@/services/Technicalassistance/Visit/Visit.js";

	import {
		Chart as ChartJS,
		Title,
		Tooltip,
		Legend,
		LineElement,
		LinearScale,
		CategoryScale,
		PointElement
	} from 'chart.js'

	ChartJS.register(
		Title,
		Tooltip,
		Legend,
		LineElement,
		LinearScale,
		CategoryScale,
		PointElement
	)

	export default {
		name: 'LineChart',
		components: {
			LineChartGenerator,
			SSelectLot
		},

		props: {
			filter : null,
			hasSearch : false,
			chartId: {
				type: String,
				default: 'line-chart'
			},
			datasetIdKey: {
				type: String,
				default: 'label'
			},
			width: {
				type: Number,
				default: 400
			},
			height: {
				type: Number,
				default: 400
			},
			cssClasses: {
				default: '',
				type: String
			},
			styles: {
				type: Object,
				default: () => {}
			},
			plugins: {
				type: Array,
				default: () => []
			}
		},

		watch: {
			hasSearch(newValue, oldValue) {
				if(this.hasSearch)
				{
					this.getDataInitial();
				}
			}
		},
		
		data() {
			return {
				year: null,
				FltObject: null,
				itemsGraph : [],
				labelGraph : [],
				chartData: {
					labels: [],
					datasets: [
						{
							label: 'Data One',
							backgroundColor: '#f87979',
							data: [40, 39, 10, 40, 39, 80, 40]
						},
						{
							label: 'Data Two',
							backgroundColor: '#1446F0',
							data: [23, 45, 5, 13, 45, 88, 10]
						}
					]
				},
				chartOptions: {
					responsive: true,
					maintainAspectRatio: false
				}
			}
		},

		methods: {
			getDataInitial() {
				this.itemsGraph = [];
				this.labelGraph = [];
					_sVisit
					.graphvisitevaluation(this.filter, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							let data = resp.data;
							if(data.length > 0)
							{
								//para obtener los encabezados o label
								let arrayLabel = [];
								arrayLabel.push(...Object.keys(data[0])); 
								arrayLabel.forEach(element => {
									if(element != "PLAGA" && element != "PLAGACOLOR")
									{
										this.labelGraph.push(element);
									}
								});
								this.chartData.labels = this.labelGraph;
								
								//para la data
								resp.data.forEach(element => {
									let objData = {};
									objData.label = element.PLAGA;
									objData.backgroundColor = element.PLAGACOLOR;
									objData.borderWidth= 3;
									objData.borderColor= element.PLAGACOLOR;;
									let data = Object.values(element);
									let newData = [];
									data.forEach(ind => {
										//console.log('agregar ', ind > 0 );
										if(ind > 0 || ind == null)
										{
											if(ind == null)
											{
												ind = 0;
											}
											newData.push(ind);
										}
									})
									objData.data = newData;
									this.itemsGraph.push(objData);
									this.chartData.datasets = this.itemsGraph;
									
								})
								
							}
						}
					});
				this.$emit("EndSearch");
			},

			downloadGraph()
			{
				
			}
		},

		created () {
			this.years = this.$fun.getTop10LastYears();
			this.year = this.$moment().year();
		},
	}
</script>
