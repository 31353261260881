<template>	
	<div class="d-flex align-center flex-column">
		<v-card :loading="loadingGrafh">		
			<v-card-title style="font-size: small;">
				<v-row>
					<v-col>
						{{ "EVOLUCIÓN DE ETAPA FENOLOGICA" }}	
					</v-col>
					<!-- <v-divider vertical></v-divider> -->
					<v-col align="right">
						<v-btn 
							fab 	
							small
							color="info"
							@click="downloadPdf()"
						>	<v-icon medium>mdi-printer</v-icon>				
						</v-btn>
					</v-col>
				</v-row>	
			</v-card-title>
			
			<v-card-subtitle>
				<v-row cols="12">
					<v-col cols="auto">
						<!-- <s-select 
							label="Año" 
							:items="years" 
							outlined 
							hide-details 
							dense 
							v-model="year"
						></s-select> -->
						<s-select-campaign-area
							label="Campaña"
							v-model="filter.CmaId"
							typeArea="1"
						></s-select-campaign-area>
					</v-col>
					<v-col cols="6">
						<s-select-lot
							label="Sub Lote"
							clearable
							return-object
							v-model="FltObject"
							full
						/>
					</v-col>
				
					<v-col align="right">
						<v-btn
							color="success"
							fab
							small
							
							@click="getDataInitial()"
						>
							<v-icon medium>mdi-magnify</v-icon>
						</v-btn>				
					</v-col>
				</v-row>
			</v-card-subtitle>			
			<technical-report @EndSearch="EndSearch($event)" @EndSearchDialog="EndSearchDialog($event)" :filter="filter" :hasSearch="hasSearch" :downloadpdf="downloadpdf" >

			</technical-report>			
		</v-card>
	</div>
</template>


<script>
	import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
	import SSelectLot from "@/components/HarvestSchedule/SSelectLot.vue";
	import TechnicalReportGraphEvaluation from './TechnicalReportGraphEvaluation.vue';

	import _sVisit from "@/services/Technicalassistance/Visit/Visit.js";

	import TechnicalReport from "./TechnicalReportGraphDialog.vue"

	import SSelectCampaignArea from "../../../components/Utils/SSelectCampaignArea.vue";

	export default {
		name: 'LineChart',
		components: {
			LineChartGenerator,
			SSelectLot,
			TechnicalReportGraphEvaluation,
			SSelectCampaignArea,
			TechnicalReport
		},

		props: {
			chartId: {
				type: String,
				default: 'line-chart'
			},
			datasetIdKey: {
				type: String,
				default: 'label'
			},
			width: {
				type: Number,
				default: 400
			},
			height: {
				type: Number,
				default: 400
			},
			cssClasses: {
				default: '',
				type: String
			},
			styles: {
				type: Object,
				default: () => {}
			},
			plugins: {
				type: Array,
				default: () => []
			}
		},

		data() {
			return {
				hasSearch : 	false,
				year: 			null,
				FltObject: 		null,
				filter: {},
				downloadpdf: 	false,
				loadingGrafh: 	false
				
			}
		
		},
		watch: {
			FltObject() {
				if(this.FltObject==null) 
				this.getDataInitial();
			},
		},
		
		
		methods: {

			downloadPdf() {
			
				this.downloadpdf = true;
			},
			

			EndSearch()
			{
				this.hasSearch = false;
				
			},

			EndSearchDialog() {
				this.loadingGrafh = false;
			},

			getDataInitial() {
				
				this.loadingGrafh = true;
				console.log("this.FltObject", this.FltObject)
				console.log("this.year", this.year)
				this.hasSearch = !this.hasSearch;
				// if(this.FltObject != null && this.year > 0)
				// {
				// console.log("this.year FltObject", this.year)
				if(this.FltObject != null){
					this.filter.FltID = this.FltObject.FltID;
				}else{
					this.filter.FltID = null;
				}
					// let BeginDateOld = this.year + '/01/01';
					// this.filter.VstDate = this.$fun.formatDateView(BeginDateOld);
					
					
				// }

			},

			downloadGraph()
			{
				
			}
		},

		created () {
			this.years = this.$fun.getTop10LastYears();
			this.year = this.$moment().year();
		},
		computed:{
			
		}
	}

	
</script>
